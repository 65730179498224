"use client";

import { useState, useRef, FormEvent, useEffect } from "react";
import { RemoteControl } from "@limbar/ui";
import ConsoleLink from "./ui/console-link";
import { SiAndroid, SiApple } from "react-icons/si";

export default function HeroHome() {
  const [demoInstance, setDemoInstance] = useState<{
    webrtcUrl: string;
    sessionId: string;
    token: string;
  } | null>(null);
  const [platform, setPlatform] = useState<'android' | 'ios'>('android');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayText, setDisplayText] = useState('Android Emulators');
  const [bgColor, setBgColor] = useState('#175134');
  const [isAnimating, setIsAnimating] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);
  const isFirefox = typeof window !== 'undefined' && navigator.userAgent.toLowerCase().includes('firefox');

  const token = "lim_108266925680eec17a9d5111233a6e9ac66b03edf9a45119";
  const organizationId = "363718f9-e771-4bc7-89fc-fdce15b03e28";

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setDisplayText(prev => {
          const nextText = prev === 'Android Emulators' ? 'iOS Simulators' : 'Android Emulators';
          setBgColor(nextText === 'iOS Simulators' ? '#922F10' : '#175134');
          return nextText;
        });
        setIsAnimating(false);
      }, 500); // Half of transition time for fading out before changing text
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const startDemo = async () => {
    try {
      const resp = await fetch(`https://eu-north1.limbar.net/apis/android.limbar.io/v1alpha1/organizations/${organizationId}/instances`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          instance: {
            metadata: {
              name: "demo-" + Math.random().toString(36).substring(7),
              organizationId: "demo"
            }
          },
          wait: true
        })
      });

      if (!resp.ok) {
        throw new Error("Failed to create instance");
      }
      const instance = await resp.json();
      setDemoInstance({
        webrtcUrl: instance.status.webrtcUrl,
        sessionId: instance.metadata.name,
        token: instance.token
      });
      
      // Scroll to phone
      phoneRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
    } catch (e) {
      console.error("Failed to create instance:", e);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    
    if (!emailRef.current?.value || isSubmitting) return;
    
    setIsSubmitting(true);
    
    try {
      const formData = new FormData();
      formData.append('email', emailRef.current.value);
      formData.append('l', '1395e8ce-7800-4f86-85a4-44eb83107e37');
      
      const response = await fetch('https://listmonk.limbar.io/subscription/form', {
        method: 'POST',
        body: formData,
      });
      
      if (response.ok) {
        setIsSubmitted(true);
      } else {
        console.error('Subscription error:', response.statusText);
        // Show error message if needed
      }
    } catch (error) {
      console.error('Subscription error:', error);
      // Show error message if needed
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="relative overflow-hidden">
      {/* Background with subtle gradient */}
      <div className="absolute inset-0 bg-[#f9f7f6]" />
      
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6">
        <div className="pt-4 pb-16 md:pt-6 md:pb-20">
          
          {/* Main hero section with flex layout */}
          <div className="flex flex-col lg:flex-row items-center">
            
            {/* Left side: Text content */}
            <div className="lg:w-1/2 lg:pr-12 mb-10 lg:mb-0">
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-medium leading-tight text-slate-900" data-aos="zoom-y-out">
                True cloud for <br />
                <span className={`rounded-lg px-5 py-1 text-white transition-all duration-1000 ${isAnimating ? 'opacity-0' : 'opacity-100'}`} style={{ backgroundColor: bgColor }}>{displayText}</span>
              </h1>
              
              <p className="mt-4 text-lg leading-8 text-gray-600 sm:mt-6 sm:text-xl" data-aos="zoom-y-out" data-aos-delay={300}>
                Access online Android emulators and iOS Simulators from anywhere, in the browser.
                <br />
                <br />
                Embed in your app, get 100s for parallel testing, or just share a link for app demos.
              </p>
              
              <div className="mt-6 flex flex-col gap-4 sm:mt-8 sm:flex-row" data-aos="zoom-y-out" data-aos-delay={450}>
                  <div className="flex flex-col">
                    {isFirefox || platform === 'ios' || demoInstance ? (
                      <>
                      <ConsoleLink
                        location="hero"
                        className="cursor-pointer inline-flex items-center justify-center rounded-lg bg-[#111827] px-5 py-2.5 text-base font-medium text-white transition-colors hover:bg-slate-800"
                      >
                        Start for free
                      </ConsoleLink>
                      <p className="text-xs text-gray-500 mt-2">No credit card required.</p> 
                      </> 
                    ) : (
                        <button
                        onClick={startDemo}
                        className="cursor-pointer inline-flex items-center justify-center rounded-lg bg-[#111827] px-5 py-2.5 text-base font-medium text-white transition-colors hover:bg-slate-800"
                      >
                        Online Demo
                      </button>
                    )}
                  </div>
              </div>
            </div>
            
            {/* Right side: Phone demo */}
            <div className="lg:w-1/2" ref={phoneRef}>
              {/* Platform toggle */}
              <div className="flex justify-center mb-4">
                <div className="bg-gray-200 p-1 rounded-full flex items-center">
                  <button
                    onClick={() => setPlatform('android')}
                    className={`flex items-center px-4 py-2 rounded-full transition-colors ${
                      platform === 'android' ? 'bg-white shadow-sm' : 'bg-transparent'
                    }`}
                  >
                    <SiAndroid className="w-5 h-5 mr-2" style={{ color: platform === 'android' ? '#3DDC84' : '#777777' }} />
                  </button>
                  <button
                    onClick={() => setPlatform('ios')}
                    className={`flex items-center px-4 py-2 rounded-full transition-colors ${
                      platform === 'ios' ? 'bg-white shadow-sm' : 'bg-transparent'
                    }`}
                  >
                    <SiApple className="w-5 h-5 mr-2" style={{ color: platform === 'ios' ? '#000000' : '#777777' }} />
                  </button>
                </div>
              </div>
              
              <div 
                className={`relative mx-auto w-[280px] sm:w-[340px] rounded-[1rem] bg-black shadow-xl border-[10px] border-gray-700 overflow-hidden ${!demoInstance && platform === 'android' ? 'cursor-pointer' : ''}`}
                onClick={() => !demoInstance && platform === 'android' && startDemo()}
              >
                {/* Main content/stream */}
                {platform === 'android' ? (
                  <div className="relative bg-[#E5E7EB] aspect-[1080/2424]">
                    <div 
                      className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-70"
                      style={{ backgroundImage: 'url(/images/demo-preview.webp)' }}
                    />
                    {demoInstance ? (
                      <RemoteControl
                        url={demoInstance.webrtcUrl}
                        token={demoInstance.token}
                        sessionId={demoInstance.sessionId}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                    ) : (
                      <div className="absolute inset-0 flex flex-col items-center justify-center gap-3">
                        {isFirefox ? (
                          <div className="text-center text-yellow-800 bg-yellow-50 p-4 rounded-lg border border-yellow-200">
                            Please use Chrome or Safari for the demo
                          </div>
                        ) : (
                          <div className="text-center text-white bg-black/50 p-4 rounded-lg">
                            Tap anywhere to start
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="relative bg-[#E5E7EB] aspect-[1080/2424] flex items-center justify-center">
                    <div className="absolute inset-0 bg-[#f9f7f6] opacity-70" />
                    
                    <div className="relative z-10 w-full max-w-xs p-6 mx-auto">
                      {!isSubmitted ? (
                        <form 
                          onSubmit={handleSubmit}
                          className="space-y-4"
                        >
                          <h3 className="text-lg font-semibold text-center mb-4">
                            Be the first to know.
                          </h3>
                          <div>
                            <input
                              type="email"
                              name="email"
                              ref={emailRef}
                              required
                              placeholder="Enter your email"
                              className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                          </div>
                          <input
                            type="submit"
                            value={isSubmitting ? "Submitting..." : "Notify me"}
                            disabled={isSubmitting}
                            className="w-full px-4 py-2 bg-[#111827] text-white font-medium rounded-md hover:bg-gray-800 transition-colors cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                          />
                        </form>
                      ) : (
                        <div className="text-center">
                          <div className="mb-4 flex justify-center">
                            <svg 
                              className="w-16 h-16 text-green-500" 
                              fill="none" 
                              stroke="currentColor" 
                              viewBox="0 0 24 24" 
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <h3 className="text-lg font-semibold mb-2">Thank You!</h3>
                          <p className="text-sm text-gray-600">
                            We'll notify you when iOS Simulators become available.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              
              {/* Decorative background pattern */}
              <div className="absolute top-1/2 right-0 -translate-y-1/2 -z-10 hidden lg:block">
                <div className="w-[600px] h-[600px] opacity-20">
                  <svg viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.3">
                      <path d="M300 600C465.685 600 600 465.685 600 300C600 134.315 465.685 0 300 0C134.315 0 0 134.315 0 300C0 465.685 134.315 600 300 600Z" fill="#175134" />
                    </g>
                    <g opacity="0.2">
                      <path d="M300 500C410.457 500 500 410.457 500 300C500 189.543 410.457 100 300 100C189.543 100 100 189.543 100 300C100 410.457 189.543 500 300 500Z" fill="#175134" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
